@tailwind base;
@tailwind components;
@tailwind utilities;

.uploadcare--widget {
  .uploadcare--widget__button_type_open {
    width: 100%;
    background-color: rgb(219 234 254);
    cursor: pointer;
    font-size: 14px;
    padding: 8px 20px;
    outline: none;
    font-weight: 500;
    color: rgb(59 130 246);
    line-height: 20px;
  }
  .uploadcare--widget__button_type_open:hover {
    background-color: rgb(191 219 254);
  }
}

.uploadcare--button_primary {
  background-color: rgb(219 234 254);
  color: rgb(59 130 246);
  cursor: pointer;
}

body {
  font-family: 'Inter Tight', sans-serif;
}
